const wrappedContracts: Record<number, string> = {
  1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', //mainnet
  10: '0x4200000000000000000000000000000000000006', //optimism
  56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', //bnb
  137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270', //polygon
  324: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91', //zksync
  1101: '0x4f9a0e7fd2bf6067db6994cf12e4495df938e6e9', // zkEVM
  42161: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1', //arbitrum
  43114: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7', //avalance
  42170: '0x722e8bdd2ce80a4422e880164f2079488e115365', //arbitrum nova
  59144: '0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f', //linea
  999: '0x8a5027ea12f45a13deb6CB96A07913c6e192BE84', //zoratestnet
  534353: '0xa1EA0B2354F5A344110af2b6AD68e75545009a03', //scrolltestnet
  534352: '0x5300000000000000000000000000000000000004', //scroll
  7777777: '0x4200000000000000000000000000000000000006', //zora
  11155111: '0x7b79995e5f793a07bc00c21412e50ecae098e7f9', //sepolia
  70700: '0x77684A04145a5924eFCE0D92A7c4a2A2E8C359de', //Apex Pop
  168587773: '0x4200000000000000000000000000000000000006', //blast sepolia
  81457: '0x4300000000000000000000000000000000000004', //blast
  3776: '0xE9CC37904875B459Fa5D0FE37680d36F1ED55e38', // astar zkEVM
  690: '0x4200000000000000000000000000000000000006', //redstone
  666666666: '0xeb54dacb4c2ccb64f8074eceea33b5ebb38e5387', // degen
  660279: '0x3fb787101dc6be47cfe18aeee15404dcc842e6af', // xai
  1482601649: '0xab01bad2c86e24d371a13ed6367bdca819589c5d', // nebula
  7560: '0x4200000000000000000000000000000000000006', // cyber
  200901: '0xff204e2681a6fa0e2c3fade68a1b28fb90e4fc5f', // bitlayer
  1329: '0xE30feDd158A2e3b13e9badaeABaFc5516e95e8C7', // sei
  1993: '0x48a9b22b80f566e88f0f1dcc90ea15a8a3bae8a4', // b3 testnet
  646: '0x48a9b22b80f566e88f0f1dcc90ea15a8a3bae8a4', // flow previewnet
  984122: '0xd5eace1274dbf70960714f513db207433615a263', // forma
  8333: '0x48a9b22b80f566e88f0f1dcc90ea15a8a3bae8a4', // b3
  33139: '0x48b62137edfa95a428d35c09e44256a739f6b557', // apechain
  360: '0x4200000000000000000000000000000000000006', // shape
  747: '0xd3bf53dac106a0290b0483ecbc89d40fcc961f3e', // flow
  2911: '0x2b1499d631bffb29eed7749b12cba754273d6da7', // hychain
  543210: '0xac98b49576b1c892ba6bfae08fe1bb0d80cf599c', // zero
  2741: '0x3439153eb7af838ad19d56e1571fbd09333c2809', // abstract
  2187: '0xfa3ed70386b9255fC04aA008A8ad1B0CDa816Fac', // game7
}

export default wrappedContracts
